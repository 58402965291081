.datasetBox {
    background-color: #ffffff;
    border-radius: 20px;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.2);
    padding: 32px 26px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    text-align: left;
    color: black
}

.datasetBox__title {
    font-size: 22px;
    font-weight: 500;
    letter-spacing: 0.2px;
    line-height: 1.5rem;
    font-family: 'Encode Sans Condensed', sans-serif;
    margin-bottom: 1rem;
}

.datasetBox__subtitle {
    font-size: 18px;
    letter-spacing: 0;
    line-height: 1.5rem;
    color: #857F87;
    margin-bottom: 1rem;
    font-family: 'Roboto', sans-serif;
}

.datasetBox:hover .linkRightArrow {
    color: #FB6E00
}

.linkRightArrow {
    color: #DD2222;
    text-decoration: none;
    font-weight: 500;
    display: flex;
    align-items: center;
    line-height: 1.5rem;
    justify-content: flex-start;
    font-size: 18px;
}