.header {
    background: linear-gradient(45.43deg, #FFC500 0%, #DD2222 32.99%, #720096 100%);
    min-height: 50vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
    font-family: 'Encode Sans Condensed', sans-serif;
    position: relative;
    text-align: center;
}

.header h1 {
    z-index: 2;
    padding: 0;
    margin: 0;
}

.header p {
    font-family: 'Roboto', sans-serif;
}

.header img {
    width: 100%;
    height: auto;
    position: absolute;
    opacity: 0.5;
}