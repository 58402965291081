.homePage {
    padding: 32px 26px;
}

.title {
    font-size: 39px;
    margin-bottom: 42px;
    font-family: 'Encode Sans Condensed', sans-serif;
    text-align: left;
}

.description {
    font-family: 'Roboto', sans-serif;
    font-size: 20px;
    max-width: 90%;
    line-height: 32px;
    padding-bottom: 30px;
    text-align: left;
}