.title {
    font-size: 40px;
    margin-bottom: 36px;
    font-family: 'Encode Sans Condensed', sans-serif;
}

.subtitle {
    font-size: 30px;
    margin-bottom: 42px;
    margin-top: 56px;
    font-family: 'Encode Sans Condensed', sans-serif;
}

.subsubtitle {
    font-size: 26px;
    margin-bottom: 42px;
    margin-top: 56px;
    font-family: 'Encode Sans Condensed', sans-serif;
}

.description {
    font-family: 'Roboto', sans-serif;
    font-size: 18px;
    max-width: 100%;
    line-height: 32px;
}

.listDescription {
    font-family: 'Roboto', sans-serif;
    font-size: 18px;
    max-width: 100%;
    line-height: 32px;
    margin-top: 0;
}

.codeSnippet {
    display: flex;
    justify-content: flex-start;
    margin-top: 30px;
    margin-bottom: 30px;
}

.codeLine {
    background-color: #f6f7f8;
    border-radius: 6px;
    border-color: #dddedf;
    border-style: solid;
    border-width: thin;
}

.links {
    color: #fb6d00;
    text-decoration: none;
}

.links:hover {
    text-decoration: underline;
}


.graphImg {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
    position: relative;
    z-index: 2;

}

.graphImg img {
    -webkit-transform: scale(0.6);
    /* Saf3.1+, Chrome */
    -moz-transform: scale(0.6);
    /* FF3.5+ */
    -ms-transform: scale(0.6);
    /* IE9 */
    -o-transform: scale(0.6);
    /* Opera 10.5+ */
    transform: scale(0.6);
    /* IE6–IE9 */
    filter: progid:DXImageTransform.Microsoft.Matrix(M11=0.9999619230641713, M12=-0.008726535498373935, M21=0.008726535498373935, M22=0.9999619230641713, SizingMethod='auto expand');
}