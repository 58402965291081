.datasets {
    margin-top: 30px;
    margin-bottom: 30px;
    display: grid;
    grid-gap: 30px;
    grid-template-columns: auto auto;
}

.datasets__item {}

@media (max-width: 1024px) {
    .datasets {
        grid-template-columns: auto auto;
    }
}

@media (max-width: 768px) {
    .datasets {
        grid-template-columns: auto;
    }
}