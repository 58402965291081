.buttonCentered {
    position: relative;
    width: 100%;
    text-align: center;
    margin-top: 100px;
}

.btn {
    padding: 9px 28px;
    border-radius: 6px;
    background-color: #8c0082;
    box-shadow: 4px 2px 8px -1px rgb(114 0 150 / 50%);
    transition: all 200ms ease;
    font-family: 'Encode Sans Semi Condensed', sans-serif;
    color: #fff;
    font-size: 16px;
    line-height: 26px;
    font-weight: 600;
    text-align: center;
    letter-spacing: 0.7px;
    text-transform: uppercase;
    text-decoration: none;
}